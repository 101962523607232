'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.contacts.factory:ContactTypeManager

 # @description

###
angular
  .module 'mundoAdmin.contacts'
  .factory 'ContactTypeManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      ContactTypeManagerBase =  new MundoDefaultManager()

      ContactTypeManagerBase.setUrl('contacts/types')
      ContactTypeManagerBase.setActions(['list', 'print', 'search'])
      ContactTypeManagerBase.setSearchFields(['translatedLabel'])
      ContactTypeManagerBase.defaultQuery.sort = 'label'


      ContactTypeManagerBase.getListSchema = ->
        [
          key: 'translatedLabel'
          title: 'datatable.label.label'
         ,
          key: 'code'
          title: 'datatable.label.code'
          hideInList: true

        ]

      ContactTypeManagerBase
  ]
